<template>
  <vx-card class="season-schedule-invoice mx-auto main-box top-zero-radius">

    <vs-row class="general-fields">
      <vs-col class="sm:pr-2 md:w-1/4 sm:w-1/2">
        <custom-validate-input :label="$t('sales.seasonSchedules.labels.user')"
                             :is-empty="true"
                             align="right"
                             :styles="{width: '100%'}"
                             :disabled="true"
                             v-model="invoice.user.name"/>
      </vs-col>

      <vs-col class="sm:pr-2 md:w-1/4 sm:w-1/2">
        <custom-validate-input :label="$t('sales.seasonSchedules.labels.startDate')"
                               :styles="{width: '100%'}"
                               :disabled="true"
                               :is-empty="true"
                               v-model="invoice.startDate"/>
      </vs-col>

      <vs-col class="sm:pr-2 md:w-1/4 sm:w-1/2">
        <custom-validate-input :label="$t('sales.seasonSchedules.labels.endDate')"
                               :styles="{width: '100%'}"
                               :disabled="true"
                               :is-empty="true"
                               v-model="invoice.endDate"/>
      </vs-col>

      <vs-col class="md:w-1/4 sm:w-1/2">
        <custom-validate-input :label="$t('sales.seasonSchedules.labels.quantity')"
                               :styles="{width: '100%'}"
                               :disabled="true"
                               :is-empty="true"
                               v-model="invoice.quantity"/>
      </vs-col>
    </vs-row>

<!--    <vs-row>-->
<!--      <vs-col class="">-->
<!--        <div class="owner-info user">-->
<!--          <span class="title">{{ $t('sales.invoices.labels.userInfo') }}</span>-->

<!--          <div @click="imageUserPreviewStatus = true"-->
<!--               class="user-image">-->
<!--            <lazy-image class="profile-avatar cursor-pointer"-->
<!--                        :src="invoice.user.avatar"-->
<!--                        :alt="invoice.user.name"/>-->
<!--          </div>-->

<!--          <image-preview v-if="imageUserPreviewStatus"-->
<!--                         :image="{src: invoice.user.avatar, alt: invoice.user.name}"-->
<!--                         @closed="imageUserPreviewStatus = false"/>-->

<!--          <div class="user-info">-->
<!--            <template v-for="(userInfo, key) in getAsiderUserProfileInfo">-->
<!--              <div class="user-info-item" :key="key">-->
<!--                <span class="pre-content">{{ userInfo.label }}</span>-->
<!--                <span class="content">{{ userInfo.content }}</span>-->
<!--              </div>-->
<!--            </template>-->
<!--          </div>-->
<!--        </div>-->
<!--      </vs-col>-->
<!--    </vs-row>-->


    <div class="products-fields">
      <draggable-dynamic-table ref="seasonScheduleSaleInvoicesList"
                               :options="options"
                               :columns="columnsLabel"
                               :data="data"
                               :zero-top-radius="false"
                               :in-modal="true"
                               @filter:remove="setFilter($event, true)"
                               @filter:set="setFilter($event)"
                               @sort:set="setSort($event)"
                               @load:more="getInvoices"/>
    </div>
    <div class="price-box">
      <div class="price-details">
        <div class="price-details-row">
          <div class="price-details-title">
            <b>{{ $t('sales.invoices.labels.totalPrice') }}</b>
          </div>

          <div class="price-details-price">
            <span>{{ addComma(parseInt(invoice.totalPrice.value)) || 0 }}</span>
            <span>{{ $t('currency') }}</span>
          </div>
        </div>

        <div class="price-details-row">
          <div class="price-details-title">
            <b>{{ $t('sales.invoices.labels.discount') }}</b>
          </div>

          <div class="price-details-price">
            <span>{{ addComma(parseInt(invoice.discount.value)) || 0 }}</span>
            <span>{{ $t('currency') }}</span>
          </div>
        </div>

        <div class="price-details-row">
          <div class="price-details-title">
            <b>{{ $t('sales.invoices.labels.finalPrice') }}</b>
          </div>

          <div class="price-details-price">
            <span>{{ addComma(parseInt(invoice.finalPrice.value)) || 0 }}</span>
            <span>{{ $t('currency') }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- insert new sale addon prompt -->
    <vs-prompt
      class="prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="selectSaleAddonPromptStatus"
      @close="selectSaleAddonPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleClick('insertInvoiceSaleAddonsBTN')">
              <custom-icon icon="CHECK" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('sales.addons.list.title') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="selectSaleAddonPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content">
        <template>
          <keep-alive>
            <select-sale-addons :user-id="invoice.user.id" @inserted="handleNewSaleAddonInserted($event)"/>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>

    <!-- receive from customer -->
    <vs-prompt
      color="danger"
      :buttons-hidden="true"
      title=""
      :active.sync="receiveFromCustomerPromptStatus">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <!--          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
                      <div>
                        <custom-icon icon="CHECK" color="success"/>
                      </div>
                    </vs-col>-->

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('treasury.receive.insert.dynamicTitle', {name: invoice.user.name.value}) }}
          </vs-col>

          <vs-spacer/>

          <!--          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
                      <router-link :to="{name: 'saleInvoice', params: {id: this.$route.params.id}}">
                        <custom-icon icon="TIMES-CIRCLE" color="danger"/>
                      </router-link>
                    </vs-col>-->
        </vs-row>
      </div>

      <div class="prompt-content p-3">
        <insert-fast-receive :user="invoice.user" :price="userDebt"
                             :subject="invoice.id.value ? $t('sales.invoices.receive.dynamicTitle', {id: invoice.id.value}) : $t('sales.invoices.receive.title')"/>

        <div class="action-buttons">
          <button class="payment" @click="handleClick('fastReceiveBTN')">{{ $t('sales.invoices.receive.labels.payment')
            }}
          </button>

          <button @click="receiveFromCustomerPromptStatus = false" class="cancel">{{
            $t('sales.invoices.receive.labels.cancel') }}
          </button>
        </div>
      </div>
    </vs-prompt>

    <!-- select cancel type -->
    <vs-prompt
      color="danger"
      :buttons-hidden="true"
      title=""
      :active.sync="showCancelTypePromptStatus">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <!--          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
                      <div>
                        <custom-icon icon="CHECK" color="success"/>
                      </div>
                    </vs-col>-->

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('sales.invoices.invoice.cancel.title') }}
          </vs-col>

          <vs-spacer/>

          <!--          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
                      <router-link :to="{name: 'saleInvoice', params: {id: this.$route.params.id}}">
                        <custom-icon icon="TIMES-CIRCLE" color="danger"/>
                      </router-link>
                    </vs-col>-->
        </vs-row>
      </div>

      <div class="prompt-content p-3">
        <div class="action-buttons">
          <button class="payment" @click="invoice.cancelType = 1, $refs.returnSaleInvoiceConfirmation.showDialog()">
            {{ $t('sales.invoices.invoice.cancel.byCustomer') }}
          </button>

          <button @click="invoice.cancelType = 2, $refs.returnSaleInvoiceConfirmation.showDialog()" class="cancel">
            {{ $t('sales.invoices.invoice.cancel.bySystem') }}
          </button>
        </div>
      </div>
    </vs-prompt>

    <vs-button id="returnSaleInvoiceBTN"
               class="w-full mt-3"
               color="danger"
               v-show="false"
               @click="$refs.returnSaleInvoiceConfirmation.showDialog()">
      {{ $t('sales.invoices.invoice.labels.cancelReserve') }}
    </vs-button>

    <custom-dialog ref="returnSaleInvoiceConfirmation"
                   :title="$t('sales.invoices.invoice.confirmations.cancelReserve.title')"
                   :body="$t('sales.invoices.invoice.confirmations.cancelReserve.body')"
                   @accept="handleReturnSaleInvoice"/>


    <vs-button id="returnRowSaleInvoiceBTN"
               class="w-full mt-3"
               color="danger"
               v-show="false"
               v-if="checkUserPermissions('invoice.cancel_addon')"
               @click="!selectDeletedRow.court ? $refs.returnRowSaleInvoiceConfirmation.showDialog() : handleCancelSaleAddon()">
      {{ $t('sales.invoices.invoice.labels.cancelAddon') }}
    </vs-button>

    <custom-dialog ref="returnRowSaleInvoiceConfirmation"
                   :title="$t('sales.invoices.invoice.confirmations.cancelAddon.title')"
                   :body="$t('sales.invoices.invoice.confirmations.cancelAddon.body')"
                   @accept="handleCancelSaleAddon"/>


    <vs-button v-if="this.checkUserPermissions('season_scheduler.delete')"
               id="deleteSeasonScheduleBTN"
               class="useral-action-button"
               @click="$refs.deleteSeasonScheduleConfirmation.showDialog()"/>

    <custom-dialog ref="deleteSeasonScheduleConfirmation"
                   :title="$t('sales.seasonSchedules.confirmations.delete.title')"
                   :body="$t('sales.seasonSchedules.confirmations.delete.body')"
                   @accept="deleteInvoice"/>


    <button v-show="false" id="receiveFromCustomer" @click="completeSaleInvoice"/>
    <!-- /receive from customer -->

    <button id="insertSaleAddon" v-show="false" @click="selectSaleAddonPromptStatus = true"/>
    <button id="insertReturnSaleInvoiceBTN" v-show="false" @click="handleClick('returnSaleInvoiceBTN')"/>
  </vx-card>
</template>

<script>
  import moment from 'moment-jalaali'
  import CustomValidateInput from '@/components/customInput/customValidateInput'
  import SuggestBox from '@/components/draggableDynamicTable/suggestBox'
  import CustomNumberInput from '@/components/customInput/customNumberInput'
  import {
    completeSaleInvoice,
    getSaleInvoice,
    cancelSaleInvoice,
    cancelSaleAddon,
    deleteSaleInvoice, insertSaleInvoiceAddon
  } from '../../../../../http/requests/sales'
  import InsertFastReceive from '../../../treasury/receive/insert/insertFastReceive'
  import axios from 'axios'
  import CustomDialog from "../../../../../components/customDialog/customDialog";
  import {
    addComma,
    checkUserPermissions,
    getAvatarUrl,
    getStaticFileUrl,
    getTimeFromServer
  } from "../../../../../assets/js/functions";
  import LazyImage from "../../../../../components/lazyImage/lazyImage";
  import ImagePreview from "../../../../../components/imagePreview/imagePreview";
  import CustomIcon from "../../../../../components/customIcon/customIcon";
  import SelectSaleAddons from "./selectSaleAddons";
  import CustomPriceInput from "../../../../../components/customInput/customPriceInput";
  import {
    deleteSeasonSchedule,
    getSeasonSchedule,
    getSeasonScheduleInvoices,
    getSeasonSchedulePrice
  } from "../../../../../http/requests/seasonSchedules";

  export default {
    name: 'seasonScheduleInvoice',
    metaInfo() {
      return {
        // title: this.invoice.type && this.invoice.id.value ? this.$t('sales.invoices.invoice.dynamicTitleWithId', {
        //   type: this.invoice.type,
        //   id: this.invoice.id.value
        // }) : this.$t('sales.invoices.invoice.title')
      }
    },
    components: {
      CustomPriceInput,
      SelectSaleAddons,
      CustomIcon,
      ImagePreview,
      LazyImage,
      CustomDialog,
      InsertFastReceive,
      CustomNumberInput,
      SuggestBox,
      CustomValidateInput,
    },
    data() {
      return {
        imageUserPreviewStatus: false,
        imageReservePreviewStatus: false,
        receiveFromCustomerPromptStatus: false,
        showCancelTypePromptStatus: false,
        selectSaleAddonPromptStatus: false,
        userDebt: 0,
        selectDeletedRow: {},
        options: {
          rowKeyField: 'id'
        },
        columnsLabel: [
          {
            field: 'status',
            i18n: 'sales.invoices.list.table.header.status',
            align: 'center',
            width: 'calc(100% / 10)',
            minWidth: 150,
            sortable: true,
            filter: true,
            filterType: 'select',
            filterTypes: [
              {
                label: this.$t('sales.invoices.statusTypes.all'),
                value: 0
              },
              {
                label: this.$t('sales.invoices.statusTypes.active'),
                value: 1
              },
              {
                label: this.$t('sales.invoices.statusTypes.activePassed'),
                value: 3
              },
              {
                label: this.$t('sales.invoices.statusTypes.canceled'),
                value: 2
              },
              {
                label: this.$t('sales.invoices.statusTypes.canceledBySystem'),
                value: 4
              },
            ]
          },
          {
            field: 'finalPrice',
            i18n: 'sales.invoices.list.table.header.finalPrice',
            width: 'calc(100% / 10)',
            minWidth: 150,
            sortable: true,
            filter: true,
            filterTypes: [
              {
                icon: 'equals',
                name: 'برابر باشد',
                i18n: 'draggableTable.filter.types.equals',
                id: 1
              },
              {
                icon: 'not-equal',
                name: 'برابر نباشد',
                i18n: 'draggableTable.filter.types.notEqual',
                id: 2
              },
              {
                icon: 'less-than-equal',
                name: 'کوچکتر مساوی',
                i18n: 'draggableTable.filter.types.lessThanEquals',
                id: 4
              },
              {
                icon: 'greater-than-equal',
                name: 'بزرگتر مساوی',
                i18n:
                  'draggableTable.filter.types.greaterThanEquals',
                id: 3
              }
            ],
            footer: {}
          },
          {
            field: 'courtName',
            i18n: 'sales.invoices.list.table.header.courtName',
            align: 'center',
            width: 'calc((100% / 10) * 2)',
            minWidth: 100,
            sortable: true,
            filter: true,
            filterType: 'select',
            filterTypes: [
              {
                label: this.$t('sales.invoices.labels.all'),
                value: 0
              },
            ]
          },
          {
            field: 'time',
            i18n: 'sales.invoices.list.table.header.time',
            align: 'center',
            width: 'calc(100% / 10)',
            minWidth: 130,
            sortable: true,
            filter: true,
            filterType: 'select',
            filterTypes: [
              {
                label: this.$t('sales.invoices.labels.all'),
                value: 0
              },
            ]
          },
          {
            field: 'reserveDate',
            i18n: 'sales.invoices.list.table.header.reserveDate',
            align: 'center',
            width: 'calc(100% / 10)',
            minWidth: 100,
            sortable: true,
            filter: true,
            filterType: 'date',
            filterRange: true,
            // filterDefault: this.$t('sales.invoices.labels.fromNow')
          },
          {
            field: 'user',
            i18n: 'sales.invoices.list.table.header.user',
            align: 'center',
            width: 'calc((100% / 10) * 2)',
            minWidth: 150,
            sortable: true,
            filter: true,
            filterTypes: [
              {
                icon: 'search',
                name: 'شامل شود',
                i18n: 'draggableTable.filter.types.search',
                id: 1
              }
            ]
          },
          {
            field: 'registryDate',
            i18n: 'sales.invoices.list.table.header.registryDate',
            align: 'center',
            width: 'calc(100% / 10)',
            minWidth: 120,
            sortable: true,
            filter: true,
            filterType: 'date',
            filterRange: true
          },
          {
            field: 'creator',
            i18n: 'sales.invoices.list.table.header.creator',
            align: 'center',
            width: 'calc((100% / 10) * 2)',
            minWidth: 130,
            sortable: true,
            filter: true,
            filterTypes: [
              {
                icon: 'search',
                name: 'شامل شود',
                i18n: 'draggableTable.filter.types.search',
                id: 1
              }
            ]
          },
          {
            field: 'id',
            i18n: 'sales.invoices.list.table.header.docNumber',
            align: 'center',
            width: 'calc(100% / 10)',
            minWidth: 80,
            sortable: true,
            filter: true,
            filterTypes: [
              {
                icon: 'search',
                name: 'شامل شود',
                i18n: 'draggableTable.filter.types.search',
                id: 1
              }
            ],
            footer: {}
          }
        ],
        invoice: {
          actionType: {
            value: '',
            isValid: true
          },
          cancelType: 1,
          id: {
            value: '',
            isValid: true
          },
          status: {
            value: '',
            isValid: true
          },
          passed: true,
          user: {
            id: 0,
            name: {
              value: '',
              isValid: true
            },
            avatar: '',
            gender: '',
            phoneNumber: ''
          },
          reserveInfo: {
            avatar: '',
            courtName: '',
            time: '',
            date: '',
            price: ''
          },
          address: {
            value: '',
            isValid: true
          },
          date: {
            value: '',
            isValid: true
          },
          startDate: {
            value: '',
            isValid: true
          },
          endDate: {
            value: '',
            isValid: true
          },
          quantity: {
            value: '',
            isValid: true
          },
          type: '',
          discount: {
            value: '0',
            isValid: true
          },
          cancelPrice: {
            value: '0',
            isValid: true
          },
          totalPrice: {
            value: '0',
            isValid: true
          },
          finalPrice: {
            value: '0',
            isValid: true
          },
          lines: [],
          selectedSaleAddon: []
        },
        invoiceProcesses: [
          {
            label: this.$t('sales.invoices.statusTypes.active'),
            value: 1
          },
          {
            label: this.$t('sales.invoices.statusTypes.canceled'),
            value: 2
          },
          {
            label: this.$t('sales.invoices.statusTypes.activePassed'),
            value: 3
          },
          {
            label: this.$t('sales.invoices.statusTypes.canceledBySystem'),
            value: 4
          },
        ],
        invoiceStatus: {
          1: {
            value: this.$t('sales.invoices.statusTypes.active'),
            // styles: 'color: rgb(119, 119, 119);'
          },
          2: {
            value: this.$t('sales.invoices.statusTypes.canceled'),
            // styles: 'color: rgb(108, 135, 41);background: rgba(108, 135, 41, .1);'
          },
          3: {
            value: this.$t('sales.invoices.statusTypes.activePassed'),
            // styles: 'color: #bb0900;background: #f8e0e0;'
          },
          4: {
            value: this.$t('sales.invoices.statusTypes.canceledBySystem'),
            // styles: 'color: #bb0900;background: #f8e0e0;'
          }
        },
        invoiceType: [
          {
            label: this.$t('sales.invoices.actionTypes.present'),
            value: 1
          },
          {
            label: this.$t('sales.invoices.actionTypes.tel'),
            value: 2
          },
          {
            label: this.$t('sales.invoices.actionTypes.internet'),
            value: 3
          }
        ],
        invoiceActionTypes: {
          1: this.$t('sales.invoices.actionTypes.present'),
          2: this.$t('sales.invoices.actionTypes.tel'),
          3: this.$t('sales.invoices.actionTypes.internet')
        },
        filters: [`timeDate=${moment(getTimeFromServer()).format(this.$validator('moment.date'))},3`],
        sorts: ['order[0]=created_at,desc'],
        data: [],
        page: 1,
        total_count: null,
        loadingTimer: 0,
        priceRequestSent: false,
        actions: [
          {
            toolbar: [
              // {
              //   id: {name: 'editSaleInvoice'},
              //   type: 'link',
              //   icon: 'EDIT',
              //   iconPack: 'useral',
              //   color: 'warning',
              //   permission: 'invoice.update'
              // },
            ],
            leftToolbar: [
              {
                id: 'deleteSeasonScheduleBTN',
                icon: 'TRASH',
                iconPack: 'useral',
                color: 'danger',
                permission: 'season_scheduler.delete'
              },
              // {
              //   id: {name: 'print-sale-invoice-page', params: {id: this.$route.params.id}},
              //   type: 'new-page-link',
              //   icon: 'PRINT',
              //   iconPack: 'useral'
              // },
              // {
              //   id: {name: 'customerSeasonSchedulesList'},
              //   type: 'link',
              //   icon: 'CHEVRON_LEFT',
              //   iconPack: 'useral'
              // }
            ]
          }
        ]
      }
    },
    computed: {
      getAsiderUserProfileInfo() {
        const info = [
          {
            label: this.$t('sales.invoices.labels.gender'),
            content: this.invoice.user.gender === 1 ? this.$t('genderTypes.man') : this.invoice.user.gender === 2 ? this.$t('genderTypes.woman') : ''
          },
          {
            label: this.$t('sales.invoices.labels.fullName'),
            content: this.invoice.user.name.value
          },
          {
            label: this.$t('sales.invoices.labels.phoneNumber'),
            content: this.invoice.user.phoneNumber
          }
        ]
        return info
      },
      getAsiderReserveProfileInfo() {
        const info = [
          {
            label: this.$t('sales.invoices.labels.courtName'),
            content: this.invoice.reserveInfo.courtName
          },
          {
            label: this.$t('sales.invoices.labels.reserveTime'),
            content: `${this.invoice.reserveInfo.time} - ${this.invoice.reserveInfo.date}`
          },
          {
            label: this.$t('sales.invoices.labels.price'),
            content: `${addComma(this.invoice.reserveInfo.price)} ${this.$t('currency')}`
          }
        ]
        return info
      }
    },
    created() {
      if (this.$route.name === 'saleInvoiceTrash') {
        this.actions[0].leftToolbar.splice(1, 1)
        this.actions[0].leftToolbar[1].id = {name: 'saleInvoicesTrash'}
      }

      setTimeout(() => {
        this.$store.dispatch('auth/setAccessToken')
        this.$store.dispatch('updateNavbarActions', this.actions[0])
        this.$store.dispatch('updateContentNavbarStyle', 'sticky')
        this.$store.dispatch('updateContentNavbarClass', '')
      }, 50)

      this.getSeasonSchedulePrice()
      this.getSaleInvoice()
    },
    methods: {
      checkUserPermissions(permission) {
        return checkUserPermissions(permission)
      },
      getSaleInvoice() {
        getSeasonSchedule(this.$route.params.id).then(response => {
          const invoice = response.data.data

          // let price = invoice.court_price
          // this.invoice.totalPrice = {
          //   value: price,
          //   isValid: true
          // }
          // this.invoice.cancelPrice = {
          //   value: invoice.cancel_price,
          //   isValid: true
          // }
          // this.invoice.finalPrice.value = invoice.total_price
          // this.invoice.discount.value = invoice.discount.toString()
          this.invoice.id = {value: invoice.id, isValid: true}
          this.invoice.startDate = {value: invoice.started_at, isValid: true}
          this.invoice.endDate = {value: invoice.ended_at, isValid: true}
          this.invoice.quantity = {value: invoice.invoices_count, isValid: true}
          this.invoice.user = {
            id: invoice.user.id,
            name: {
              value: `${invoice.user.name} ${invoice.user.family}`,
              isValid: true
            },
            avatar: invoice.user.avatar ? getAvatarUrl(invoice.user.avatar) : invoice.user.gender === 1 ? getStaticFileUrl('/images/man-avatar.png') : getStaticFileUrl('/images/woman-avatar.png'),
            gender: invoice.user.gender,
            phoneNumber: invoice.user.phone_number
          }

          switch (invoice.action_type) {
            case 1:
              this.invoice.type = this.$t('sales.invoices.actionTypes.shortPresent')
              break

            case 2:
              this.invoice.type = this.$t('sales.invoices.actionTypes.shortTel')
              break

            case 3:
              this.invoice.type = this.$t('sales.invoices.actionTypes.shortInternet')
              break
          }

          const actions = JSON.parse(JSON.stringify(this.actions[0]))
          const editSaleAddonIndex = actions.toolbar.map(e => e.id).indexOf('editSaleInvoice')
          if (invoice.status === 2 && editSaleAddonIndex > -1) {
            actions.toolbar.splice(editSaleAddonIndex, 1)
          }
          if (invoice.passed) {
            const trashIndex = actions.leftToolbar.map(e => e.icon).indexOf('TRASH')
            if (trashIndex > -1) {
              actions.leftToolbar.splice(trashIndex, 1)
            }
          }
          if (invoice.status === 1 && !invoice.passed && this.$route.name === 'saleInvoice') {
            actions.toolbar.push({
              id: invoice.status === 3 ? {
                name: 'insertReturnSaleInvoice',
                params: {id: this.$route.params.id}
              } : 'insertReturnSaleInvoiceBTN',
              type: invoice.status === 3 ? 'link' : null,
              icon: 'RETURN_SALE',
              iconPack: 'useral',
              color: 'danger',
              style: 'margin-right: 5px;',
              permission: 'invoice.cancel'
            })
          } else {
            const delete_index = this.columnsLabel.map(e => e.field).indexOf('delete')
            if (delete_index > -1) {
              this.columnsLabel.splice(delete_index, 1)
            }
          }

          const delete_index = this.columnsLabel.map(e => e.field).indexOf('delete')
          if (delete_index > -1 && (invoice.status === 2 || !checkUserPermissions('invoice.cancel_addon'))) {
            this.columnsLabel.splice(delete_index, 1)
          }

          actions.leftToolbar.push({
            id: {name: 'customerSeasonSchedulesList', params: {userId: this.invoice.user.id}},
            type: 'link',
            icon: 'CHEVRON_LEFT',
            iconPack: 'useral'
          })

          setTimeout(() => {
            this.$store.dispatch('updateNavbarActions', actions)
          }, 50)

          this.$store.dispatch('setPageTitle', this.$t('sales.invoices.invoice.dynamicTitle', {type: this.invoice.type}))
        })
      },
      getInvoices() {
        if (!this.requestSent) {
          this.requestSent = true

          clearTimeout(this.loadingTimer)
          this.loadingTimer = setTimeout(() => {
            if (this.data.length < this.total_count || !this.total_count) {
              if (this.$refs.seasonScheduleSaleInvoicesList && this.data.length === 0) this.$refs.seasonScheduleSaleInvoicesList.loadMoreStatus = 'FirstLoad'
              else if (this.$refs.seasonScheduleSaleInvoicesList && this.data.length > 0) this.$refs.seasonScheduleSaleInvoicesList.loadMoreStatus = 'Loading'

              getSeasonScheduleInvoices(this.$route.params.id, this.page, this.filters, this.sorts).then((response) => {
                const invoices = response.data

                invoices.data.forEach((invoice) => {
                  let status = ''
                  if (invoice.passed) {
                    if (invoice.status === 1) {
                      status = this.invoiceStatus[3]
                    } else if (invoice.status === 2) {
                      status = this.invoiceStatus[2]
                    }
                  } else {
                    if (invoice.status === 1) {
                      status = this.invoiceStatus[invoice.status]
                    } else if (invoice.status === 2) {
                      status = this.invoiceStatus[2]
                      if (invoice.cancel_type === 2) {
                        status = this.invoiceStatus[4]
                      }
                    }
                  }

                  this.data.push({
                    route: {name: 'saleInvoice', params: {id: invoice.id}},
                    id: invoice.invoice_number || invoice.id,
                    registryDate: invoice.invoice_date,
                    reserveDate: invoice.reserved_time && invoice.reserved_time.date ? invoice.reserved_time.date : '',
                    time: {
                      value: invoice.reserved_time && invoice.reserved_time.time ? `${invoice.reserved_time.time.start_time.substr(0, 5)} ${this.$t('sales.invoices.labels.until')} ${invoice.reserved_time.time.end_time.substr(0, 5)} ` : '',
                      styles: 'direction: rtl;'
                    },
                    courtName: invoice.reserved_time.court.name || '',
                    finalPrice: {value: invoice.total_price || '0', type: 'price'},
                    user: `${invoice.user.name} ${invoice.user.family}`,
                    creator: invoice.creator.name,
                    receivedId: invoice.received_id || '-',
                    type: this.invoiceActionTypes[invoice.action_type],
                    status: status,
                    timeStatus: invoice.passed ? this.$t('sales.invoices.labels.passed') : this.$t('sales.invoices.labels.active'),
                    reserveStatus: invoice.status === 1 ? this.$t('sales.invoices.labels.reserved') : this.$t('sales.invoices.labels.canceled'),
                    styles: invoice.status === 2 ? 'background: #f8e0e0;' : invoice.passed ? 'background: #f0f0f0;' : ''
                  })
                })

                this.total_count = invoices.pagination.total
                this.page = invoices.pagination.current_page + 1

                if (response.data.pagination.current_page === 1) {
                  const row_index = this.columnsLabel.map((e) => {
                    return e.field
                  }).indexOf('id')
                  this.columnsLabel[row_index].footer.value = response.data.pagination.total
                }

                if (this.$refs.seasonScheduleSaleInvoicesList) this.$refs.seasonScheduleSaleInvoicesList.loadMoreStatus = ''

                this.requestSent = false
              }).catch((error) => {
                console.log(error)
                if (axios.isCancel(error)) {
                  this.$vs.notify({
                    title: this.$t('alert.duplicateRequest.title'),
                    text: this.$t('alert.duplicateRequest.message'),
                    icon: 'icon-alert-circle',
                    iconPack: 'feather',
                    time: 2400,
                    color: 'warning'
                  })
                } else if (this.$refs.seasonScheduleSaleInvoicesList) this.$refs.seasonScheduleSaleInvoicesList.loadMoreStatus = 'Danger'

                this.requestSent = false
              })
            } else {
              this.requestSent = false
            }
          }, 400)
        }
      },
      getSeasonSchedulePrice () {
        getSeasonSchedulePrice(this.$route.params.id).then(response => {
          const prices = response.data.data

          this.invoice.totalPrice.value = prices.total + prices.discount
          this.invoice.discount.value = prices.discount
          this.invoice.finalPrice.value = prices.total
        })
      },
      getInvoiceStatus(id) {
        return this.invoiceProcesses[id - 1].label
      },
      getInvoiceActionType(id) {
        return this.invoiceType[id - 1].label
      },
      completeSaleInvoice() {
        completeSaleInvoice(this.$route.params.id).then(() => {
          this.$vs.notify({
            title: this.$t('alert.message.title'),
            text: this.$t('sales.invoices.notifications.complete.success'),
            color: 'success',
            icon: 'icon-check',
            iconPack: 'feather',
            time: 2400
          })
          this.receiveFromCustomerPromptStatus = false

          setTimeout(() => {
            this.$store.dispatch('updateNavbarActions', this.actions[0])
          }, 50)

          // window.open(this.$router.resolve({name: 'print-sale-invoice-page', params:{id: this.$route.params.id}}).href, '_blank')
        }).catch(error => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          } else {
            const error_mapper = {
              'name': ''
            }
            switch (error.response.status) {
              case 409:
                if (error.response.data && error.response.data.data && error.response.data.data.hasOwnProperty('user_debt')) {
                  const newInvoice = error.response.data.data

                  this.$vs.notify({
                    title: this.$t('alert.error.title'),
                    text: this.$t('sales.invoices.notifications.lowMoney'),
                    color: 'warning',
                    icon: 'icon-alert-circle',
                    iconPack: 'feather',
                    time: 2400
                  })

                  this.userDebt = Math.abs(newInvoice.user_debt)
                  this.receiveFromCustomerPromptStatus = true

                } else {
                  this.$vs.notify({
                    title: this.$t('alert.error.title'),
                    text: this.$t('sales.invoices.notifications.noTimeExist'),
                    color: 'danger',
                    icon: 'icon-alert-circle',
                    iconPack: 'feather',
                    time: 2400
                  })
                }
                break

              case 422:
                Object.keys(error.response.data.errors).forEach((error_key) => {
                  const err = error_key.toString().split('.')
                  if (error_mapper[err[err.length - 1]]) {
                    this.$vs.notify({
                      title: this.$t('alert.error.title'),
                      text: error_mapper[err[err.length - 1]],
                      color: 'danger',
                      icon: 'icon-alert-circle',
                      iconPack: 'feather',
                      time: 2400
                    })
                    error_mapper[err[err.length - 1]] = null
                  }
                })
                break

              default:
                this.$vs.notify({
                  title: this.$t('alert.error.title'),
                  text: this.$t('sales.invoices.notifications.complete.error'),
                  color: 'danger',
                  icon: 'icon-alert-circle',
                  iconPack: 'feather',
                  time: 2400
                })
                break
            }
          }
        })
      },
      handleClick(id) {
        document.getElementById(id).click()
      },
      addComma(val) {
        let sign = ''

        if (val < 0) sign = '-'

        val = val.toString()

        if (val[0] === '0') val = val.substr(1, val.length - 1)

        return val.toString().replace(/\D/g, '')
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + sign
      },
      handleReturnSaleInvoice() {
        const payload = {
          cancel_type: this.invoice.cancelType
        }
        cancelSaleInvoice(this.$route.params.id, payload).then(response => {
          this.$vs.notify({
            title: this.$t('alert.message.title'),
            text: this.$t('sales.invoices.notifications.cancel.success'),
            color: 'success',
            icon: 'icon-check',
            iconPack: 'feather',
            time: 2400
          })
          this.showCancelTypePromptStatus = false
          this.$router.replace({name: 'saleInvoices'})
        })
          .catch(error => {
            if (axios.isCancel(error)) {
              this.$vs.notify({
                title: this.$t('alert.duplicateRequest.title'),
                text: this.$t('alert.duplicateRequest.message'),
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400,
                color: 'warning'
              })
            } else {
              switch (error.response.status) {
                case 409:
                  this.$vs.notify({
                    title: this.$t('alert.error.title'),
                    text: this.$t('sales.invoices.notifications.timesCanceled'),
                    color: 'danger',
                    icon: 'icon-alert-circle',
                    iconPack: 'feather',
                    time: 2400
                  })
                  break
                default:
                  this.$vs.notify({
                    title: this.$t('alert.error.title'),
                    text: this.$t('sales.invoices.notifications.cancel.error'),
                    time: 2400,
                    color: 'danger',
                    icon: 'icon-alert-circle',
                    iconPack: 'feather'
                  })
                  break
              }
            }
          })
      },
      handleCancelSaleAddon() {
        const row = this.selectDeletedRow
        if (!row.court) {
          if (!row.required) {
            const payload = {
              cancel_type: 1
            }
            cancelSaleAddon(this.$route.params.id, row.id, payload).then(() => {
              this.$vs.notify({
                title: this.$t('alert.message.title'),
                text: this.$t('sales.invoices.notifications.cancelSaleAddon.success'),
                color: 'success',
                icon: 'icon-check',
                iconPack: 'feather',
                time: 2400
              })
              this.getSaleInvoice()
            })
              .catch(error => {
                if (axios.isCancel(error)) {
                  this.$vs.notify({
                    title: this.$t('alert.duplicateRequest.title'),
                    text: this.$t('alert.duplicateRequest.message'),
                    icon: 'icon-alert-circle',
                    iconPack: 'feather',
                    time: 2400,
                    color: 'warning'
                  })
                } else {
                  switch (error.response.status) {
                    case 409:
                      this.$vs.notify({
                        title: this.$t('alert.error.title'),
                        text: this.$t('sales.invoices.notifications.addonCanceled'),
                        color: 'danger',
                        icon: 'icon-alert-circle',
                        iconPack: 'feather',
                        time: 2400
                      })
                      break
                    default:
                      this.$vs.notify({
                        title: this.$t('alert.error.title'),
                        text: this.$t('sales.invoices.notifications.cancelSaleAddon.error'),
                        time: 2400,
                        color: 'danger',
                        icon: 'icon-alert-circle',
                        iconPack: 'feather'
                      })
                      break
                  }

                }
              })
          } else {
            this.$vs.notify({
              title: this.$t('alert.error.title'),
              text: this.$t('sales.invoices.notifications.cantDeleteAddon'),
              color: 'danger',
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400
            })
          }
        } else {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('sales.invoices.notifications.cantDeleteCourt'),
            color: 'danger',
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400
          })
        }

      },

      handleNewSaleAddonInserted(list) {
        if (list && list.length > 0) {
          this.selectedSaleAddon = []
          list.forEach(item => {
            const addonId = this.invoice.lines.map(e => e.id).indexOf(item.id)
            if (addonId === -1) {
              this.selectedSaleAddon.push({
                sale_addon_id: item.id,
                quantity: item.quantity
              })
            } else {
              this.$vs.notify({
                title: this.$t('alert.error.title'),
                text: this.$t('sales.invoices.notifications.addonExist', {name: item.name}),
                color: 'danger',
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 4000
              })
            }
          })
        }
        if (this.selectedSaleAddon.length > 0 && checkUserPermissions('invoice.add_addon')) {
          const payload = {addons: this.selectedSaleAddon}
          insertSaleInvoiceAddon(this.$route.params.id, payload).then(() => {
            this.$vs.notify({
              title: this.$t('alert.message.title'),
              text: this.$t('sales.invoices.notifications.insertAddon.success'),
              color: 'success',
              icon: 'icon-check',
              iconPack: 'feather',
              time: 2400
            })
            this.selectSaleAddonPromptStatus = false
            this.getSaleInvoice()
          })
            .catch(error => {
              if (axios.isCancel(error)) {
                this.$vs.notify({
                  title: this.$t('alert.duplicateRequest.title'),
                  text: this.$t('alert.duplicateRequest.message'),
                  icon: 'icon-alert-circle',
                  iconPack: 'feather',
                  time: 2400,
                  color: 'warning'
                })
              } else {
                switch (error.response.status) {
                  case 409:
                    // if (error.response.data && error.response.data.data && error.response.data.data.hasOwnProperty('user_debt')) {
                    const invoice = error.response.data

                    if (invoice.error_type === 'balance_error') {
                      this.$vs.notify({
                        title: this.$t('alert.error.title'),
                        text: this.$t('sales.invoices.notifications.lowMoneyForAddon'),
                        color: 'danger',
                        icon: 'icon-alert-circle',
                        iconPack: 'feather',
                        time: 2400
                      })

                      this.userDebt = Math.abs(invoice.money_deficit)
                      this.invoice.id.value = invoice.id
                      this.receiveFromCustomerPromptStatus = true
                    } else {
                      this.$vs.notify({
                        title: this.$t('alert.error.title'),
                        text: this.$t('sales.invoices.notifications.timesReserved'),
                        color: 'danger',
                        icon: 'icon-alert-circle',
                        iconPack: 'feather',
                        time: 2400
                      })
                    }
                    break

                  default:
                    this.$vs.notify({
                      title: this.$t('alert.error.title'),
                      text: this.$t('sales.invoices.notifications.insertAddon.error'),
                      color: 'danger',
                      icon: 'icon-alert-circle',
                      iconPack: 'feather',
                      time: 2400
                    })
                    break
                }
              }
              this.selectSaleAddonPromptStatus = false
            })
        }
      },
      setFilter(filters) {
        let filters_list = []
        const attributes = []
        Object.keys(filters).forEach((key) => {
          switch (key) {

            case 'id':
              if (filters[key].search !== '') filters_list.push(`invoiceNumber=${filters[key].search}`)
              break

            case 'receivedId':
              if (filters[key].search !== '') filters_list.push(`receivedId=${filters[key].search}`)
              break

            case 'creator':
              let search = filters[key].search
              if (search === 'سیستم' || search === 'سیستمی') {
                search = 'system'
              }
              if (filters[key].search !== '') filters_list.push(`creator=${ search}`)
              break

            case 'user':
              if (filters[key].search !== '') filters_list.push(`user=${filters[key].search}`)
              break

            case 'registryDate':
              if (filters[key].search.length > 0) filters_list.push(`date=${filters[key].search.join('_')}`)
              break

            case 'reserveDate':
              if (filters[key].search === this.$t('sales.invoices.labels.fromNow')) {
                filters_list.push(`timeDate=${moment(getTimeFromServer()).format(this.$validator('moment.date'))},3`)
              } else if (filters[key].search.length > 0) filters_list.push(`timeDate=${filters[key].search.join('_')}`)
              break

            case 'discount':
              if (filters[key].search !== '') filters_list.push(`discount=${filters[key].search},${filters[key].type.id}`)
              break

            case 'finalPrice':
              if (filters[key].search !== '') filters_list.push(`total_price=${filters[key].search},${filters[key].type.id}`)
              break

            case 'courtName':
              if (filters[key].search.value > 0) filters_list.push(`court=${filters[key].search.value}`)
              break

            case 'time':
              if (filters[key].search.value > 0) filters_list.push(`time=${filters[key].search.value}`)
              break

            case 'type':
              if (filters[key].search.value > 0) filters_list.push(`actionType=${filters[key].search.value}`)
              break

            case 'status':
              if (filters[key].search.value === 1) {
                filters_list.push(`passed=0`)
                filters_list.push(`status=1`)
              } else if (filters[key].search.value === 2) {
                filters_list.push(`status=2`)
              } else if (filters[key].search.value === 3) {
                filters_list.push(`passed=1`)
                filters_list.push(`status=1`)
              } else if (filters[key].search.value === 4) {
                filters_list.push(`cancelType=2`)
                filters_list.push(`status=2`)
              } else if (filters[key].search.value > 0) filters_list.push(`status=${filters[key].search.value}`)
              break

            case 'financialStatus':
              if (filters[key].search.value > 0) filters_list.push(`financialStatus=${filters[key].search.value}`)
              break
          }
        })
        filters_list = [filters_list, ...attributes]

        this.data = []
        this.page = 1
        this.total_count = 0
        this.filters = filters_list
        this.getInvoices()
        // this.getInvoicesPrice()
      },
      setSort(sorts) {
        const sorts_list = []
        Object.keys(sorts).forEach((key) => {
          switch (key) {
            case 'id':
              sorts_list.push(`order[0]=id,${sorts[key] ? 'desc' : 'asc'}`)
              break

            case 'receivedId':
              sorts_list.push(`order[0]=received_id,${sorts[key] ? 'desc' : 'asc'}`)
              break

            case 'creator':
              sorts_list.push(`order[0]=creator,${sorts[key] ? 'desc' : 'asc'}`)
              break

            case 'registryDate':
              sorts_list.push(`order[0]=created_at,${sorts[key] ? 'desc' : 'asc'}`)
              break

            case 'user':
              sorts_list.push(`order[0]=user,${sorts[key] ? 'desc' : 'asc'}`)
              break

            case 'type':
              sorts_list.push(`order[0]=type,${sorts[key] ? 'desc' : 'asc'}`)
              break

            case 'status':
              sorts_list.push(`order[0]=status,${sorts[key] ? 'desc' : 'asc'},order[1]=time,${sorts[key] ? 'desc' : 'asc'}`)
              break

            case 'date':
              sorts_list.push(`order[0]=invoice_date,${sorts[key] ? 'desc' : 'asc'}`)
              break

            case 'courtName':
              sorts_list.push(`order[0]=court,${sorts[key] ? 'desc' : 'asc'}`)
              break

            case 'reserveDate':
              sorts_list.push(`order[0]=reserved_date,${sorts[key] ? 'desc' : 'asc'}`)
              break

            case 'time':
              sorts_list.push(`order[0]=time,${sorts[key] ? 'desc' : 'asc'}`)
              break

            case 'discount':
              sorts_list.push(`order[0]=discount,${sorts[key] ? 'desc' : 'asc'}`)
              break

            case 'finalPrice':
              sorts_list.push(`order[0]=total_price,${sorts[key] ? 'desc' : 'asc'}`)
              break

            case 'financialStatus':
              sorts_list.push(`order[0]=financial_status,${sorts[key] ? 'desc' : 'asc'}`)
              break
          }
        })

        if (sorts_list.length === 0) {
          this.sorts.push('order[0]=updated_at,desc')
        }

        this.data = []
        this.page = 1
        this.total_count = 0
        this.sorts = sorts_list
        this.getInvoices()
      },
      deleteInvoice() {
        deleteSeasonSchedule(this.$route.params.id).then(() => {
          this.$vs.notify({
            title: this.$t('alert.message.title'),
            text: this.$t('sales.seasonSchedules.notifications.delete.success'),
            color: 'success',
            icon: 'icon-check',
            iconPack: 'feather',
            time: 2400
          })
          this.$router.replace({name: 'customerSeasonSchedulesList', params: {userId: this.invoice.user.id}})
        }).catch((error) => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          } else {
            switch (error.response.status) {
              case 409:
                this.$vs.notify({
                  title: this.$t('alert.error.title'),
                  text: this.$t('sales.seasonSchedules.notifications.cantDelete'),
                  color: 'danger',
                  icon: 'icon-alert-circle',
                  iconPack: 'feather',
                  time: 2400
                })
                break
              default:
                this.$vs.notify({
                  title: this.$t('alert.message.title'),
                  text: this.$t('sales.seasonSchedules.notifications.delete.error'),
                  color: 'danger',
                  icon: 'icon-alert-circle',
                  iconPack: 'feather',
                  time: 2400
                })
                break
            }
          }
        })
      },
      handleOpenSelectSaleAddonPrompt(index) {
        this.selectSaleAddonPromptStatus = true
        this.selectedIndex = index
      },
    },
    watch: {
      '$store.state.helper.documentsChanged': {
        handler(val) {
          if (val) {
            this.receiveFromCustomerPromptStatus = false
            this.handleNewSaleAddonInserted()
          }
        },
        deep: true
      }
    }
  }
</script>

<style lang="scss">
  @import "../../../../../assets/scss/vuexy/variables";

  .season-schedule-invoice {

    .vx-card__body {
      padding: 0 !important;
      display: flex;
      flex-direction: column;

      .general-fields, .products-fields, .owner-info {
        padding: .5rem;
      }

      .process-timeline {
        background: #cecece22;
      }

      .general-fields {
        background: #cecece22;
        border-bottom: 1px solid #cecece;
        padding-bottom: 0;

        .custom-validate-input label {
          background-color: #ffffff;
        }

        .custom-validate-input.danger {
          label {
            background-color: #fad8d8;

            .input-label {
              background: linear-gradient(180deg, #f8f8f8, #fad8d8);
            }
          }
        }

        .custom-validate-input label span.is-focus,
        .custom-number-input label span.is-focus {
          backdrop-filter: sepia(1);
          background: linear-gradient(180deg, #f8f8f8, #ffffff);
        }
      }

      .products-fields {
        flex-grow: 1;
        height: calc(100vh - 355px) !important;
        min-height: 215px;
        display: flex;
        flex-direction: column;

        .draggable-dynamic-table {

          .new-suggest-modal-button {
            display: none;
          }

          .suggest-modal-button {
            right: 7px !important;
          }
        }
      }

      .price-box {
        padding: 0 0.5rem 0.5rem;
      }

      .price-details {
        display: block;
        border: 1px solid #cecece;
        border-radius: .5rem;
        width: 220px;
        margin-left: auto;
        text-align: center;
        line-height: 40px;

        .price-details-row {
          display: flex;
          border-bottom: 1px solid #cecece;

          &:last-child {
            border: none;
          }

          .price-details-title {
            width: 50%;
            border-right: 1px solid #cecece;
            background: #cecece50;
          }

          .price-details-price {
            display: flex;
            justify-content: center;
            width: 50%;

            span:first-child {
              margin-right: 5px;
              font-weight: bold;
            }

            span:last-child {

            }
          }
        }
      }

      .owner-info {
        position: relative;
        margin: 15px 0.5rem 0;
        height: calc(100% - 15px);
        border: 1px solid #cdcdcd;
        border-radius: 0.5rem;
        display: flex;

        .title {
          font-size: 14px;
          font-weight: bold;
          position: absolute;
          top: -12px;
          left: 10px;
          padding: 0 5px;
          background-color: #ffffff;
        }

        .user-image {
          max-width: 50px;
          max-height: 50px;
          border-radius: 0.5rem;
          overflow: hidden;
          display: flex;
          align-items: center;

          .profile-avatar {
            width: 100%;
            object-fit: cover;
          }
        }

        .user-info {
          padding: 0 0.5rem;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .user-info-item {
            line-height: 32px;

            .pre-content {
              font-size: 12px;
              color: #888888;
            }

            .content {
              font-weight: bold;
            }
          }
        }

      }
    }

    .select-user-invoice {
      position: relative;

      .suggest-modal-button, .new-suggest-modal-button {
        position: absolute;
        bottom: 0;
        right: 42px;
        top: 20px;
        width: 25px;
        height: 25px;
        padding: 5px;
        border: 1px solid #00bcd4;
        border-radius: .5rem;
        color: #00bcd4;
        background: white;
        cursor: pointer;
      }

      .new-suggest-modal-button {
        right: 12px;
        border: 1px solid #28C76F;
        color: #28C76F;
      }

      .suggest-box {

        ul {
          margin-top: -10px;
          border-radius: .5rem;
          padding: 0 10px;
          line-height: 35px;
          background: white;
          box-shadow: 0 5px 10px -8px;
          border: 1px solid #cecece;
          /*right: .5rem;
          left: .5rem;*/

          &::-webkit-scrollbar {
            display: none;
          }

          li {
            cursor: pointer;
            border-top: 1px solid #cecece;

            &:first-child {
              border-top: none !important;
            }
          }
        }
      }
    }

    table {
      border: 1px solid #1b1b1c;
      border-radius: 5px;

      tr {

        div.select-suggest-list {
          right: 0 !important;
          left: 0 !important;
        }
      }
    }
  }

  .action-buttons {
    display: flex;

    button {
      flex: 1;
      line-height: 40px;
      border-radius: .5rem;
      border: none;
      color: #ffffff;
      transition: all .3s ease;
      cursor: pointer;

      &.payment {
        margin-right: 10px;
        background: #33b000cf;

        &:hover {
          background: #33b000;
        }
      }

      &.cancel {
        background: #b00000af;

        &:hover {
          background: #b00000;
        }
      }
    }
  }
</style>
